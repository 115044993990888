<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div
            class="mb-4"
            style="display: flex; align-items:center; justify-content: space-between;"
          >
            <h3>Customer Reviews</h3>
            <button
              class="btn"
              style="background-color: #D96B12; color: white;"
              @click="showaddReviewModal"
              v-if="$store.state.currentRole == 'ADMIN'"
            >
              Add Reviews
            </button>
          </div>
          <em v-if="isLoading">Loading Reviews...</em>
          <!-- <span v-if="reviews.error" class="text-danger">ERROR: {{ reviews.error }}</span>
          <div v-if="reviews.items"> -->

          <div class="search_add_section row">
            <div class="col-md-6">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="reviews"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="loading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getReviews"
                  v-else-if="!loading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load reviews Now. Please click here to retry
                </div>
                <div v-if="!loading && !loadingError">No reviews</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Name"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="auto"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.name }} </span>
                </template>
              </el-table-column>

              <el-table-column
                label="Review"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.review }} </span><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                v-if="$store.state.currentRole == 'ADMIN'"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="false"
                        @click="editReview(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="false"
                        @click="deleteReview(scope.row.id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Add A Review`"
            :visible.sync="addReviewModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="reviewForm"
                :rules="rules"
                ref="reviewForm"
                class="demo-reviewForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Reviewer Name</label>
                    <el-form-item prop="name">
                      <el-input v-model="reviewForm.name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Review Message</label>
                    <el-form-item prop="review">
                      <el-input v-model="reviewForm.review"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #D96B12; color: white;"
                    @click="addReview('reviewForm')"
                  >Add Review</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="reviewForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>

    <article>
      <section>
        <div class="courseUnits_dialogs">
          <el-dialog
            :title="`Edit A Review`"
            :visible.sync="editReviewModal"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            <div v-loading="submitting">
              <el-form
                :model="reviewForm"
                :rules="rules"
                ref="reviewForm"
                class="demo-reviewForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Reviewer Name</label>
                    <el-form-item prop="name">
                      <el-input v-model="reviewForm.name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Review Message</label>
                    <el-form-item prop="review">
                      <el-input v-model="reviewForm.review"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    style="background-color: #D96B12; color: white;"
                    @click="UpdateReview('reviewForm')"
                  >Edit Review</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures, All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--v-model="reviewForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>

  </div>
</template>

<script>
// import { format, parseISO } from "date-fns";
export default {
  data() {
    return {
      loading: false,
      isLoading: false,
      reviews: [],
      reviewEditId: "",

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "name",
          value: "",
        },
      ],

      addReviewModal: false,
      editReviewModal: false,
      loadingError: false,
      submitting: false,
      reviewForm: {
        name: "",
        review: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Reviewer Name is required",
            trigger: "blur",
          },
        ],
        review: [
          {
            required: true,
            message: "Review Message is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.getReviews();
  },

  methods: {
    handleClose(done) {
      done();
    },

    showaddReviewModal() {
      this.addReviewModal = true;
      this.reviewForm.name = "";
      this.reviewForm.review = "";
    },

    async getReviews() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`reviews`);
        if (request.data.success) {
          this.reviews = request.data.reviews;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Reviews now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async addReview(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(
              `reviews/add`,
              this.reviewForm
            );
            if (
              response.data.success &&
              response.data.message == "ADDED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.getReviews();
              this.$notify({
                title: "Success",
                message: "Review added successfully",
                type: "success",
              });
              this.addReviewModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to add Review",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async deleteReview(id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Review. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`reviews/delete/${id}`);
            if (
              request.data.success &&
              request.data.message === "REVIEW_DELETED_SUCCESSFULLY"
            ) {
              this.getReviews();
              this.$notify({
                title: "Success",
                message: "Review deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Review, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },

    editReview(reviewEditData) {
      this.editReviewModal = true;
      this.reviewForm.name = reviewEditData.name;
      this.reviewForm.review = reviewEditData.review;
      this.reviewEditId = reviewEditData.id;
    },

    UpdateReview(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let response = await this.$http.post(
              `reviews/edit/${this.reviewEditId}`,
              this.reviewForm
            );
            if (
              response.data.success &&
              response.data.message == "REVIEW_EDITED_SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.getReviews();
              this.$notify({
                title: "Success",
                message: "Review updated successfully",
                type: "success",
              });
              this.editReviewModal = false;
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to update Review",
              message: "An unexpected Error occurred, please try again",
              type: "error",
            });
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>